const currentPage = window.location.href;
var coll = document.getElementsByClassName("collapsible"),
	menuLinks = document.querySelectorAll('nav a'),
	info = document.getElementById('info'),
	nav = document.querySelector('nav');

// Liens

document.addEventListener("readystatechange", function() {
    for (var i = 0; i < menuLinks.length; i++) {
        if(menuLinks[i].href === currentPage){
            menuLinks[i].classList.add('active');
        }
    }
})

// Texte dépliant

for (i = 0; i < coll.length; i++) {
	coll[i].addEventListener("click", function() {
    	this.classList.toggle("open");
    	var content = this.nextElementSibling;
    	if (content.style.maxHeight){
    		content.style.maxHeight = null;
    	} else {
    		content.style.maxHeight = content.scrollHeight + "px";
    	} 
  	});
}

// Fade on load

window.onload = function() {
    document.querySelector('main').style.opacity="1";
}